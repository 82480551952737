<template>
    <div class="container" style="display:flex;">
        <div style="width:40%;">
            武器列表
            <el-tree
                style="margin-top:20px;"
                ref="tree"
                    :data="tagList"                    
                    node-key="id"
                    :expand-on-click-node="false"            
                    @node-click="handleItemClick"
                    :props="tagDefaultProps"
                    >
            </el-tree>
            <!-- :render-content="renderContent" -->
            <!-- show-checkbox -->
        </div>
        <div style="width:60%;">
            <div style="display:flex;flex-direction:column;align-item:center;">
                <div>当前节点 <span style="color: #0066ff;">{{curNode.label}}</span></div>
                <div style="display:flex;justify-content:center;">
                    <el-button v-if="curNode.label" style="margin-left:20px;" type="text" @click="onEdit(1)">修改当前节点</el-button>
                    <el-button v-if="curNode.label && curNode.level<1" style="margin-left:20px;" type="text" @click="onEdit(2)">新增子节点</el-button>
                    <el-button v-if="curNode.label" style="margin-left:20px;" type="text" @click="onEdit(3)">新增兄弟节点</el-button>
                    <el-button style="margin-left:20px;" type="text" @click="onEdit(4)">新增一级节点</el-button>
                    <el-button v-if="curNode.label" style="margin-left:20px;" type="text" @click="onEdit(5)">删除当前节点</el-button>
                </div>
            </div>
        </div>
        <el-dialog title="新建/编辑标签" v-model="editDialogVisible" width="80%">
            <el-form :model="editForm"  label-width="150px" label-position="left">
                <el-form-item label="标签名称">
                    <el-input v-model="editForm.name" autocomplete="off"></el-input>
                </el-form-item>                
                <el-form-item v-if="editForm.level===1" label="筛选维度">                            
                    <el-select @change="timesChanged" v-model="editForm.filterId"  placeholder="请选择筛选维度">
                        <el-option
                            v-for="item in filterList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-row  v-if="editForm.level===1">
                  <p style="text-align: left; margin: 0 0 20px 45px;font-size: 14px;color: #606266;font-weight: 700;">标签</p>
                    <div  class="edit_dev" >
                      <el-transfer
                        style="text-align: left; display: inline-block;"
                        v-model="editForm.selTagIdArray"
                        filterable                        
                        :render-content="renderFunc"
                        :titles="['备选标签', '已选标签']"
                        :button-texts="['移除', '选中']"
                        :format="{
                          noChecked: '${total}',
                          hasChecked: '${checked}/${total}'
                        }"                        
                        :data="labelList">
                      </el-transfer>
                    </div>                    
                </el-row>
                <el-form-item label="说明"  style="margin-top:20px;">
                    <!-- <el-input v-model="editForm.description" autocomplete="off"></el-input> -->
                    <el-input v-model="editForm.description" size="medium" :rows="4" type="textarea"
								 autocomplete="off" placeholder="请输入说明"
								></el-input>
                </el-form-item>

            </el-form>
            <div style="display:flex;justify-content:center;">
                <el-button @click="editDialogVisible = false">取消</el-button>
                <el-button type="primary" @click="onSaveEvent">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

import { mapGetters } from "vuex";
import {Label_Group_Save, Label_Group_Delete} from "@/api/tag";
import {getToken} from "@/utils/auth";

    export default {
        name: "cart",
        components:{},
        data(){
            return {
                renderFunc(h, option) {
                    // return <span>{ option.key } - { option.label }</span>;
                    return <span>{ option.label }</span>;
                },
                                
                tagList:[],  
                curNode:{},
                // optFlag:0,
                editForm:{
                    id:0,
                    pId:0,
                    name:'',
                    labelList:[],       //标签库的所有标签列表
                    description:'',
                    filterId:1,
                    selTagIdArray:[],  //选中的标签列表
                    level:0,
                },
                editDialogVisible:false,

                tagDefaultProps: {
                    children: 'children',
                    label: 'label',
                    labelSimple:'labelSimple',
                    value: 'value',
                    pid:'pid',
                    level:0,
                    description:''
                },
                parentId:[],

                level1Id:0, //获得当前选中项第一级的Id
                level2Id:0, //获得当前选中项第二级的Id

                filterList:[{label:'按区',value:1},{label:'按省',value:2},{label:'按城市级别',value:3},{label:'按站(LED)',value:4},{label:'按站(混合)',value:5}],

            }
        },
        computed: {...mapGetters(['GetTagGroupList','labelList','Label_Group_LabelList'])},

        async mounted(){
            this.getTags();  
            await this.$store.dispatch('tag/labelList');
        },

        methods: {
            async onSaveEvent(){
                var userId = getToken();
                const {id,pId,name,description,filterId,selTagIdArray,level} = this.editForm;
                if (!name?.trim()) {
					this.$message.warning('请输入标签名称');
					return;
                }
                if (level===1){
                    if (!filterId){
                        this.$message.warning('请选择筛选维度');
					    return;
                    }
                    if (selTagIdArray.length<1){
                        this.$message.warning('请选择标签');
					    return;
                    }
                }else{
                    this.selTagIdArray=[];
                }
                await Label_Group_Save({
							id,name,pId, memo:description,typeId:filterId,labelIds:selTagIdArray.join(',')									
						}).then(()=>{
                            this.$message.success('保存成功');
                            this.getTags().then(()=>{
                                this.dealExpand();
                                this.editDialogVisible=false;
                            })  
                            
                        })
            },

            async onEdit(flag){
                // if(!this.curNode.id){
                //     return;
                // }
                var userId = getToken();
                if (flag===5){                    
					this.$confirm('是否确认删除：'+this.curNode.label+', 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(async () => {
						await Label_Group_Delete({
							userId,
							id:this.curNode.id,													
						}).then(()=>{
                            this.$message.success('删除成功');                            
                            this.getTags().then(()=>{
                                this.level1Id=0;
                                if (this.curNode.level===1){
                                    this.level1Id=this.curNode.pId;
                                }
                                this.dealExpand();
                            })                             
                        })
					})
                }else{
                    this.level1Id=0;
                    if(flag===1){   //修改
                        this.editForm.id=this.curNode.id;
                        this.editForm.name=this.curNode.labelSimple;
                        this.editForm.description=this.curNode.description;
                        this.editForm.pId=this.curNode.pId; 
                        this.editForm.level=this.curNode.level; 
                        if (this.curNode.level===1){
                            await this.$store.dispatch('tag/Label_Group_LabelList',{groupId:this.curNode.id}).then(()=>{
                                // this.editForm.selTagIdArray=this.Label_Group_LabelList;
                                var subArr=[];
                                this.Label_Group_LabelList.forEach(element => {
                                    if (element){        
                                        subArr.push(parseInt(element.labelId));
                                    }
                                });
                                this.editForm.selTagIdArray=subArr;
                                // console.log('aa',this.editForm.selTagIdArray,this.Label_Group_LabelList)

                                this.level1Id=this.curNode.pId;
                            })                            
                        }
                    }
                    if(flag===2){   //新增子节点
                        this.editForm.id=0;
                        this.editForm.name='';
                        this.editForm.description='';
                        this.editForm.selTagIdArray=[];
                        this.editForm.pId=this.curNode.id;
                        this.editForm.level=this.curNode.level+1;
                        if (this.curNode.level===0){
                            this.level1Id=this.curNode.id;
                        }
                        if (this.curNode.level===1){
                            this.level1Id=this.curNode.pId;
                        }
                    }
                    if(flag===3){   //新增兄弟节点
                        this.editForm.id=0;
                        this.editForm.name='';
                        this.editForm.pId=this.curNode.pId;
                        this.editForm.level=this.curNode.level;
                        this.editForm.selTagIdArray=[];
                        if (this.curNode.level===1){
                            this.level1Id=this.curNode.pId;
                        }
                    }
                    if(flag===4){   //新增一级节点
                        this.editForm.id=0;
                        this.editForm.name='';
                        this.editForm.pId=0;
                        this.editForm.selTagIdArray=[];
                        this.editForm.level=0;
                    }
                    this.editDialogVisible=true;
                }
                
                
            },

            async getTags(){
                await this.$store.dispatch('operate/GetTagGroupList').then(()=>{
                    var obj1=[];
                    this.GetTagGroupList.forEach(ele1 => {
                        var obj1Tmp={};
                        obj1Tmp.id=ele1.Id;
                        obj1Tmp.label=ele1.Name;
                        obj1Tmp.labelSimple=ele1.Name;
                        obj1Tmp.pId=ele1.ParentId;
                        obj1Tmp.level=ele1.level;
                        obj1Tmp.description=ele1.description;
                        obj1Tmp.children=[];
                        ele1.SubTag.forEach(ele2 => {
                            var obj2Tmp={};
                            obj2Tmp.id=ele2.Id;
                            obj2Tmp.label=ele2.Name;
                            obj2Tmp.labelSimple=ele2.Name;
                            obj2Tmp.pId=ele2.ParentId;
                            obj2Tmp.level=ele2.level;
                            obj2Tmp.description=ele2.description;
                            obj2Tmp.children=[];
                            ele2.SubTag.forEach(ele3 => {
                                var obj3Tmp={};
                                obj3Tmp.id=ele3.Id;
                                if (ele3.description.length>0){
                                    obj3Tmp.label=ele3.Name+'('+ele3.description+')';
                                }else{
                                    obj3Tmp.label=ele3.Name;
                                }
                                obj3Tmp.labelSimple=ele3.Name;
                                obj3Tmp.pId=ele3.ParentId;
                                obj3Tmp.level=ele3.level;
                                obj3Tmp.description=ele3.description;
                                obj2Tmp.children.push(obj3Tmp);
                            });
                            obj1Tmp.children.push(obj2Tmp)
                        });
                        obj1.push(obj1Tmp);
                    });
                    this.tagList = obj1;
                    // 
                });  
            },

            dealExpand(){
                if (this.level1Id>0){        
                    console.log('zhankai 1',this.level1Id)                               
                    this.$refs.tree.store.nodesMap[this.level1Id].expanded = true;
                }
                if (this.level2Id>0){             
                    console.log('zhankai 2',this.level2Id)                             
                    this.$refs.tree.store.nodesMap[this.level2Id].expanded = true;
                }
            },


            handleItemClick(item){
                // console.log(item);
                this.curNode=item;
            },

          
        }
    }
</script>

<style scoped>
    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
    }

   .edit_dev >>> .el-transfer-panel {
     width:350px;
   }

</style>